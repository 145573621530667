import React from 'react'
import EmporioLogo from '../assets/emporioLogoSemNome.png'

import './sobrenos.estilo.scss'

export default function SobreNos() {
    return (
        <>
            <div className='conteinerGeralSobreNos'>
                <h1>Sobre Nós</h1>
                <div>
                    <div className='conteinerPrimeiroSobreNos'>
                        <div className='divPrimeiroSobreNos'>
                            <p>
                                Desde 2016, o Sabores do Gaúcho tem sido um verdadeiro ponto de referência na cidade de Bauru,
                                oferecendo aos seus clientes o que há de melhor em sabores artesanais.
                                Nossa história começa com uma paixão pelas tradições do Sul do Brasil e o desejo de trazer um
                                pedaço dessas raízes para o coração de São Paulo.
                            </p>
                            <p>
                                Nossos produtos, como as linguiças artesanais, são preparados com o cuidado e a dedicação que
                                só quem conhece a arte da charcutaria pode oferecer.
                            </p>
                        </div>
                        <div className='divSegundoSobreNos'>
                            <img src={EmporioLogo} alt="SaboresGauchLogo" />
                        </div>
                    </div>
                    <div className='divTerceiroSobreNos'>
                        <p>
                            Cada tempero é selecionado com critério, garantindo uma explosão de sabores que remete às autênticas tradições gaúchas. As cachaças
                            artesanais, por sua vez, são produzidas com técnicas que combinam o que há de mais tradicional
                            com inovações que resultam em bebidas únicas, perfeitas para acompanhar qualquer ocasião.
                            Ao longo dos anos, conquistamos o paladar dos bauruenses e de visitantes de diversas regiões,
                            mantendo sempre o compromisso com a qualidade e a autenticidade. Sabores do Gaúcho não é
                            apenas um lugar para comprar produtos; é uma experiência que transporta nossos clientes
                            para um mundo onde o artesanal é valorizado e o sabor é sempre protagonista.
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}